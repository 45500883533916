import React from "react";

function Errorpage() {
  return (
    <div className="bg-gray-900 min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8 font-poppins" style={{
      minHeight: "100vh"
    }}>
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-golden-100 sm:text-5xl">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-white sm:pl-6">
              <h1 className="text-4xl font-extrabold text-blue-500 tracking-tight sm:text-5xl">Page not found</h1>
              <p className="mt-1 text-base text-white">Please check the URL in the address bar and try again.</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <a href="/" className="bg-blue-500 text-white transition ease-in-out delay-120 duration-300 hover:bg-golden-100 border border-transparent hover:border-golden-100 py-3 px-6 font-semibold rounded-lg shadow-md inline-block">Go back home</a>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Errorpage;