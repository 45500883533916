import React, { useEffect, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/free-regular-svg-icons";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import Aos from "aos";
import "aos/dist/aos.css";

function Location({ margin_top, data, duration }) {
    useEffect(() => {
        Aos.init({ delay: 200, once: false });
    }, []);

    return (
        <Fragment>
            <section className={margin_top ? "b-20 relative block bg-gray-900 " + margin_top : "b-20 relative block bg-gray-900"}>
                <div
                    className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
                    style={{ height: "80px" }}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-gray-900 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>
                <div className="container mx-auto px-4 pt-20 lg:pt-24 lg:pb-64">
                    <div className="flex flex-wrap text-center justify-center">
                        <div className="w-full lg:w-6/12 px-4" data-aos={data ? data : "fade-down"} data-aos-duration={duration ? duration : "1500"}>
                            <h2 className="text-4xl font-semibold text-white">
                                Offices
                            </h2>
                            <p className="text-lg leading-relaxed mt-4 mb-20 md:mb-40 lg:mb-10 text-gray-400">
                                We currently have two different office locations.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative block py-24 lg:pt-0 bg-gray-900">
                <div className="container mt-10 md:mt-0 mx-auto px-4">
                    <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-golden-100" data-aos={data ? data : "fade-up-right"} data-aos-duration={duration ? duration : "1500"}>
                                <div className="flex-auto p-5 lg:p-10">
                                    <p className="text-center font-semibold text-xl">Plano Office</p>
                                    <div className="relative w-full mb-3 mt-3 flex items-center justify-center">
                                        <div className="flex-1 text-sm md:text-base bg-white p-5 rounded-md shadow-md mb-4">
                                            <p className=""><span><FontAwesomeIcon className="mr-5" icon={faLocationDot} /></span> 7800 N Dallas Parkway, Suite 100, Plano, TX 75024</p>
                                            {/* mb-3  */}
                                            {/* <p className=""><span><FontAwesomeIcon className="mr-4" icon={faPhone} /></span> 1-800-XXX-XXXXX</p> */}
                                        </div>
                                    </div>
                                    <p className="text-center font-semibold text-xl">Texarkana Office</p>
                                    <div className="relative w-full mb-3 mt-3 flex items-center justify-center">
                                        <div className="flex-1 bg-white text-sm md:text-base p-5 rounded-md shadow-md mb-4">
                                            <p className=""><span><FontAwesomeIcon className="mr-5" icon={faLocationDot} /></span> 5940 Summerhill Road, Texarkana, TX 75503</p>
                                            {/* mb-3  */}
                                            {/* <p className=""><span><FontAwesomeIcon className="mr-4" icon={faPhone} /></span> 903-791-0151</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

export default Location;