import React, { Fragment } from "react";
import Career from "../components/Careers.js";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";

function about_page() {
    return (
        <Fragment>
            <Navbar bg_color="bg-gray-900" />
            <Career />
            <Footer />
        </Fragment>
    );
}

export default about_page;