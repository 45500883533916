import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/free-regular-svg-icons";
import { faChartLine, faSlidersH, faLightbulb, faLock } from "@fortawesome/free-solid-svg-icons";
import Aos from "aos";
import "aos/dist/aos.css";

function Technology() {
    useEffect(() => {
        Aos.init({ delay: 200, once: false });
    }, []);

    return (
        <section className="relative pb-20 bg-darkblue-200 lg:py-20 lg:bg-blue-500 text-white">
            <div
                className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
                style={{ height: "80px" }}
            >
                <svg
                    className="absolute bottom-0 overflow-hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                >
                    <polygon
                        className="text-darkblue-100 lg:text-blue-500 fill-current"
                        points="2560 0 2560 100 0 100"
                    ></polygon>
                </svg>
            </div>
            <div className="relative container mx-auto lg:px-4" style={{
                minHeight: "100vh",
                maxWidth: "100vw"
            }}>
                <div className="lg:hidden absolute top-0 w-full h-full bg-center bg-cover"
                    style={{
                        backgroundImage: "url('images/technology.jpg')"
                    }}>
                    <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                </div>
                <div className="items-center flex flex-wrap">
                    <div className="w-full lg:w-4/12 mt-20 ml-auto mr-auto px-4" data-aos="fade-down" data-aos-duration="500">
                        <div className="relative" data-aos="fade-down" data-aos-duration="1500">
                            <img
                                alt="LENDEM Solutions Technology"
                                className="hidden lg:block w-full rounded-lg shadow-lg"
                                src="images/technology.jpg"
                            />
                            <div className="absolute inset-0 rounded-lg bg-gray-400 mix-blend-multiply" aria-hidden="true" />
                        </div>
                    </div>
                    <div className="w-full lg:w-4/12 lg:ml-auto lg:mr-auto px-4">
                        <div className="md:pr-12 ml-5 lg:ml-0">
                            <div data-aos="fade-down" data-aos-duration="1500">
                                <p className="mt-8 lg:mt-0 font-semibold text-lg text-amber-300 text-center lg:text-right">How You Will Benefit</p>
                                <h3 className="text-3xl font-bold text-center lg:text-right">
                                    Cutting-Edge Technology
                                </h3>
                                <p className="mt-4 mb-3 lg:mb-0 text-md leading-relaxed text-center lg:text-right text-white">
                                    We obsess over creating elegant technology solutions using data analytic tools and marketing pathways to grow our clients'
                                    businesses with robust and scalable solutions.
                                </p>
                            </div>
                            <div className="flex flex-wrap">
                                <div className="pt-6 w-full px-4 text-center" data-aos="fade-up" data-aos-duration="1000">
                                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg">
                                        <div className="px-3 py-3 flex-auto">
                                            <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-golden-100">
                                                <FontAwesomeIcon className="w-6 h-6" icon={faChartLine} />
                                            </div>
                                            <h6 className="text-xl font-semibold text-black">Lead Generation</h6>
                                            <p className="mt-2 mb-4 text-gray-600">
                                                We have a multitude of relationships in the marketplace with proprietary valuation algorithms to drive volume and deliver financial performance.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-6 w-full px-4 text-center" data-aos="fade-up" data-aos-duration="1000">
                                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg">
                                        <div className="px-3 py-3 flex-auto">
                                            <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-golden-100">
                                                <FontAwesomeIcon className="w-6 h-6" icon={faLock} />
                                            </div>
                                            <h6 className="text-xl font-semibold text-black">Security</h6>
                                            <p className="mt-2 mb-4 text-gray-600">
                                            From authentication to encryption and fraud to data privacy, we continuously move forward with an eye toward the regulations, methods and strategies that ensure our clients retain their customers’ trust.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-6 w-full px-4 text-center" data-aos="fade-up" data-aos-duration="1500">
                                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded-lg">
                                        <div className="px-3 py-3 flex-auto">
                                            <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-golden-100">
                                                <FontAwesomeIcon className="w-6 h-6" icon={faSlidersH} />
                                            </div>
                                            <h6 className="text-xl font-semibold text-black">Scoring Model Development</h6>
                                            <p className="mt-2 mb-4 text-gray-600">
                                                Combining data from our industry-leading human capital and top-notch expertise allows us to develop advanced scoring models to solve your biggest business problems.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Technology;