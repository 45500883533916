import React, { Fragment } from "react";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";

function partners_page() {
    return (
        <Fragment>
            <Navbar bg_color="bg-gray-900" />
            <section className="pb-20 font-poppins bg-white mt-20">
                <div className="container mx-auto px-4">
                    <div className="flex flex-wrap items-center mt-32">
                        <div className="w-full lg:w-5/12 px-4 mr-auto ml-auto">
                            <small className="text-blue-500 font-semibold text-lg"></small>
                            <h3 className="text-2xl mb-2 font-bold leading-normal">
                                Loan Management Software & Data Providers
                            </h3>
                            <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                                When you become a client, you not only gain the benefit of
                                our experience and expertise, but also the relationships we
                                have built over the past 20+ years. We take great pride in
                                the services we provide but also owe much of our success
                                to these relationships we have built. With a relentless
                                drive to improve, LENDEM is always searching for and
                                testing third-parties to partner with. As our network
                                continues to build, so does our ability to help you achieve
                                your goals.
                            </p>
                        </div>
                        <div className="w-full lg:w-5/12 px-4 mr-auto ml-auto">
                            <div className="h-full flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blue-500">
                                <div className="relative">
                                    <img
                                        alt="LENDEM Solution Network"
                                        src="images/data_people.jpg"
                                        className="w-full h-96 align-middle rounded-t-lg"
                                    />
                                    <div className="absolute rounded-lg inset-0 bg-gray-400 mix-blend-multiply" aria-hidden="true" />
                                </div>
                                {/* <blockquote className="relative p-8 mb-4">
                                    <svg
                                        preserveAspectRatio="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 583 95"
                                        className="absolute left-0 w-full block"
                                        style={{
                                            height: "95px",
                                            top: "-94px"
                                        }}
                                    >
                                        <polygon
                                            points="-30,95 583,95 583,65"
                                            className="text-blue-500 fill-current"
                                        ></polygon>
                                    </svg>
                                    <h4 className="text-xl font-bold text-white">
                                        LENDEM Solutions
                                    </h4>
                                    <p className="text-md font-light mt-2 text-white">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean in eros sed libero aliquet fermentum. Vestibulum at risus molestie felis aliquam dignissim sed quis est.
                                    </p>
                                </blockquote> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Fragment>
    );
}

export default partners_page;