import React from "react";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import Hero from "../components/Hero.js";
import About from "../components/About.js";
import Advantages from "../components/Advantages.js";
import Technology from "../components/Technology.js";
import Leadership from "../components/Leadership.js";
import Locations from "../components/Locations.js";

export default function Homepage() {
  return (
    <main className="font-poppins">
      <Navbar />
      <Hero />
      <About />
      <Advantages />
      <Technology />
      <Leadership />
      {/* <Locations /> */}
      <Footer />
    </main>
  );
}
