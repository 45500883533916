import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faXmark, faBars } from "@fortawesome/free-solid-svg-icons";
import { Popover, Transition } from "@headlessui/react";

function Navbar({bg_color}) {
    return (
        <Popover className={bg_color ? bg_color + " flex justify-between md:justify-around p-5 mx-auto top-0 absolute z-50 w-full font-poppins" : "flex justify-between md:justify-around p-5 mx-auto top-0 absolute z-50 w-full font-poppins"}>
            <div className="flex items-center">
                <a href="/">
                    <img className="h-12 w-auto" src="images/lendem_solutions_logo.png" alt="LENDEM Solutions Logo" />
                    {/* block */}
                </a>
            </div>
            <div className="-mr-10 my-3 md:hidden">
                <Popover.Button className="absolute right-5 top-6 bg-blue-500 rounded-md p-2 inline-flex items-center justify-center text-white transition ease-in-out delay-120 duration-300 hover:bg-golden-100">
                    <span className="sr-only">Open menu</span>
                    <FontAwesomeIcon className="w-6 h-6" icon={faBars} aria-hidden="true" />
                </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden items-center md:flex lg:flex space-x-8">
                <a href="/about" className="font-semibold text-lg text-white transition ease-in-out delay-120 duration-300 hover:text-blue-500">About Us</a>
                <a href="/solutions" className="font-semibold text-lg text-white transition ease-in-out delay-120 duration-300 hover:text-blue-500">Solutions</a>
                <a href="/partners" className="font-semibold text-lg text-white transition ease-in-out delay-120 duration-300 hover:text-blue-500">Partners</a>
                <a href="/careers" className="font-semibold text-lg text-white transition ease-in-out delay-120 duration-300 hover:text-blue-500">Careers</a>
            </Popover.Group>
            {/* <div className="hidden md:flex lg:flex items-center space-x-2">
                <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon className="w-10 h-10 text-white cursor-pointer transition ease-in-out delay-120 duration-300 hover:text-blue-500" icon={faFacebookSquare} />
                </a>
                <a href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon className="w-10 h-10 text-white cursor-pointer transition ease-in-out delay-120 duration-300 hover:text-golden-100" icon={faLinkedin} />
                </a>
            </div> */}
            <Transition
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            ></Transition>
            <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50">
                <div className="rounded-lg shadow-lg ring-1 ring-white ring-opacity-5 bg-white divide-y-2 divide-golden-100">
                    <div className="pt-5 pb-6 px-5">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <a href="/">
                                    <img className="block h-12 w-auto" src="images/lendem_solutions_logo.png" alt="lendem" />
                                </a>
                            </div>
                            <div className="-mr-10 my-3">
                                <Popover.Button className="absolute right-5 top-6 bg-blue-500 rounded-md p-2 inline-flex items-center justify-center text-white transition ease-in-out delay-120 duration-300 hover:bg-golden-100">
                                    <span className="sr-only">
                                        Close menu
                                    </span>
                                    <FontAwesomeIcon className="w-6 h-6 text-white" icon={faXmark} />
                                </Popover.Button>
                            </div>
                        </div>
                    </div>
                    <div className="py-6 px-5">
                        <div className="grid grid-cols-2 gap-4">
                            <a href="/about" className="font-semibold text-lg text-blue-500 hover:text-golden-100 md:text-white transition ease-in-out delay-120 duration-300 md:hover:text-blue-500">About Us</a>
                            <a href="/solutions" className="font-semibold text-lg text-blue-500 hover:text-golden-100 md:text-white transition ease-in-out delay-120 duration-300 md:hover:text-blue-500">Solutions</a>
                            <a href="/partners" className="font-semibold text-lg text-blue-500 hover:text-golden-100 md:text-white transition ease-in-out delay-120 duration-300 md:hover:text-blue-500">Partners</a>
                            <a href="/careers" className="font-semibold text-lg text-blue-500 hover:text-golden-100 md:text-white transition ease-in-out delay-120 duration-300 md:hover:text-blue-500">Careers</a>
                            {/* <a href="https://www.facebook.com/" target="_blank" className="font-semibold text-lg text-blue-500 hover:text-golden-100 md:text-white transition ease-in-out delay-120 duration-300 md:hover:text-blue-500">Facebook</a>
                            <a href="https://www.linkedin.com/" target="_blank" className="font-semibold text-lg text-blue-500 hover:text-golden-100 md:text-white transition ease-in-out delay-120 duration-300 md:hover:text-blue-500">Linkedin</a> */}
                        </div>
                    </div>
                </div>
            </Popover.Panel>
        </Popover>
    );
}

export default Navbar;