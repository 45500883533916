import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/free-regular-svg-icons";
import { faPeopleGroup, faMicrochip, faHandHoldingDollar, faChartLine, faClipboardCheck } from "@fortawesome/free-solid-svg-icons";

function Solutions() {
    return (
        <Fragment>
            <div className="relative w-full h-full pt-16 pb-25 md:pb-32 2xl:pb-30 flex content-center items-center justify-center"
                style={{
                    minHeight: "50vh"
                }}>
                <div className="absolute top-0 w-full h-full bg-center bg-cover"
                    style={{
                        backgroundImage: "url('images/solutions.jpg')"
                    }}>
                    <span className="w-full h-full absolute opacity-75 bg-black"></span>
                </div>
                <div className="container mx-auto px-4 pt-10 2xl:pt-[10rem] 3xl:pt-0">
                    <div className="items-center flex flex-wrap">
                        <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                            <div className="relative">
                                <h2 className="text-3xl font-bold text-blue-500 font-poppins">
                                    Innovative Solutions - Pragmatic Approach
                                </h2>
                                <p className="text-white leading-relaxed m-4 font-poppins">
                                    {/* Whether you’re looking for a targeted skill to fill
                                    an internal deficiency or a turn-key solution, we have the experience and
                                    expertise to help you achieve your objectives. */}
                                    Bringing together teams of experts in our five core tenants – omni-channel customer acquisition, decisioning,
                                    customer retention, vintage maximization, and compliance – gives our clients the ability to provide credit with scalable products and services that fit their tolerances for risk and overall performance requirements.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="container mx-auto w-full h-full pt-20 md:pt-40 lg:pt-20 2xl:pt-[5rem] 3xl:pt-[35.5rem] 4xl:pt-[8.5rem] font-poppins">
                <div className="flex flex-col mt-5 lg:mt-12 justify-center items-center">
                    <div className="pt-6 w-full flex justify-center items-center lg:w-4/12 text-center">
                        <div className="relative flex flex-col min-w-[80vw] break-words w-full">
                            <div className="px-3 py-3 flex-auto">
                                <h6 className="text-4xl font-semibold text-blue-500 font-poppins">Our Solutions</h6>
                                <p className="mt-5 mb-4 text-gray-700 leading-relaxed">
                                    Bringing together teams of experts in our five core tenants – omni-channel customer acquisition, decisioning,
                                    customer retention, vintage maximization, and compliance – gives our clients the ability to provide credit with scalable products and services that fit their tolerances for risk and overall performance requirements.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container mx-auto w-full h-full pb-40 font-poppins">
                <div className="flex flex-col mt-5 lg:mt-12 justify-center items-center">
                    <div className="pt-6 w-full flex justify-center items-center lg:w-4/12 text-center">
                        <div className="relative flex flex-col min-w-[80vw] break-words w-full">
                            <div className="px-3 py-3 flex-auto">
                                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-golden-100">
                                    <FontAwesomeIcon className="w-6 h-6 text-white" icon={faPeopleGroup} />
                                </div>
                                <h6 className="text-xl font-semibold text-blue-500">Omni-Channel Customer Acquisition</h6>
                                <p className="mt-2 mb-4 text-gray-600">
                                    Whether you operate storefront locations or exclusively
                                    online, your customers must be able to find you. Utilizing
                                    our vast experience in Ping Tree Maximization, Digital
                                    Advertising and Direct Mail, we are able to match you with
                                    customers that fit your desired customer profile.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="pt-6 w-full flex justify-center items-center lg:w-4/12 text-center">
                        <div className="relative flex flex-col min-w-[80vw] break-words w-full">
                            <div className="px-3 py-3 flex-auto">
                                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-golden-100">
                                    <FontAwesomeIcon className="w-6 h-6 text-white" icon={faMicrochip} />
                                </div>
                                <h6 className="text-xl font-semibold text-blue-500">Decisioning</h6>
                                <p className="mt-2 mb-4 text-gray-600">
                                    Regardless of the customer acquisition channel, in todays
                                    world you must be able to make instant credit decisions –
                                    because consumers are looking for instant answers. By
                                    utilizing multiple proprietary predictive scoring models and
                                    leveraging years of third-party data relationships, we are
                                    able to assess risk of nonpayment, fraud and propensity to
                                    convert in real time. Understanding that all companies do
                                    not face the same constraints and have various appetites
                                    for risk, our models were built to be customizable allowing
                                    you to tune to your specific needs.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="pt-6 w-full flex justify-center items-center lg:w-4/12 text-center">
                        <div className="relative flex flex-col min-w-[80vw] break-words w-full">
                            <div className="px-3 py-3 flex-auto">
                                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-golden-100">
                                    <FontAwesomeIcon className="w-6 h-6 text-white" icon={faHandHoldingDollar} />
                                </div>
                                <h6 className="text-xl font-semibold text-blue-500">Customer Retention</h6>
                                <p className="mt-2 mb-4 text-gray-600">
                                    Just as new customers are not created equal, returning
                                    customers are equally as unique. We understand our job
                                    is not done once we have matched you with your
                                    “perfect” customer. To maximize lifetime values of
                                    consumers, the consumer must be pleased with the
                                    product and the service received.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="pt-6 w-full flex justify-center items-center lg:w-4/12 text-center">
                        <div className="relative flex flex-col min-w-[80vw] break-words w-full">
                            <div className="px-3 py-3 flex-auto">
                                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-golden-100">
                                    <FontAwesomeIcon className="w-6 h-6 text-white" icon={faChartLine} />
                                </div>
                                <h6 className="text-xl font-semibold text-blue-500">Vintage Maximization</h6>
                                <p className="mt-2 mb-4 text-gray-600">
                                    Data Science serves as our foundational core, but we also
                                    understand that data without context, is meaningless. To
                                    succeed in this fast-paced market one must understand
                                    consumer behavior and expectations and have a profound
                                    understanding of the products being offered. While strong
                                    vintages must begin with precise decisioning, we
                                    understand that for you to be successful, you must provide
                                    your customer with ongoing support and custom-tailored
                                    experiences. While other companies may be able to help
                                    with a “Yes/No” funding decision, we have the tools,
                                    experience and relationships to help your team provide
                                    value along the entire customer lifecycle.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="pt-6 w-full flex justify-center items-center lg:w-4/12 text-center">
                        <div className="relative flex flex-col min-w-[80vw] break-words w-full">
                            <div className="px-3 py-3 flex-auto">
                                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-golden-100">
                                    <FontAwesomeIcon className="w-6 h-6 text-white" icon={faClipboardCheck} />
                                </div>
                                <h6 className="text-xl font-semibold text-blue-500">Compliance</h6>
                                <p className="mt-2 mb-4 text-gray-600">
                                    We understand the complexities of your business,
                                    including the complexity of compliance in today’s ever-
                                    changing marketplace. We have a dedicated team of
                                    professionals committed to supporting your internal
                                    compliance framework.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* <div className="bg-blue-500 font-poppins">
                <div className="mx-auto max-w-2xl py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                        <span className="block">So what are you waiting for?</span>
                        <span className="block">Join our team today!</span>
                    </h2>
                    <a
                        href="#"
                        className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-white bg-golden-100 hover:bg-indigo-50 sm:w-auto"
                    >
                        Contact Us
                    </a>
                </div>
            </div> */}
        </Fragment>
    );
}

export default Solutions;

