import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

function About({ margin_top, data, duration, link }) {
    useEffect(() => {
        Aos.init({ delay: 200, once: false });
    }, []);

    return (
        <section className={margin_top ? "pb-20 font-poppins bg-white " + margin_top : "pb-20 font-poppins bg-white -mt-24"}>
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap items-center mt-32">
                    <div className="w-full lg:w-5/12 px-4 mr-auto ml-auto" data-aos={data ? data : "fade-down"} data-aos-duration={duration ? duration : "1000"}>
                        <small className="text-blue-500 font-semibold text-lg">About Us</small>
                        <h3 className="text-3xl mb-2 font-bold leading-normal">
                            LENDEM Solutions
                        </h3>
                        <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                            LENDEM is an analytics and decision management company that
                            specializes in customizable solutions to help your organization make
                            smarter, faster fraud, credit risk and collections decisions through big
                            data and machine learning.
                        </p>
                        <a href={link ? link : "/about"} className="mb-6 bg-golden-100 text-white transition ease-in-out delay-120 duration-300 hover:bg-white hover:text-golden-100 border border-transparent hover:border-golden-100 py-3 px-5 font-semibold rounded-lg shadow-md inline-block">Learn More</a>
                    </div>
                    <div className="w-full lg:w-4/12 px-4 mr-auto ml-auto" data-aos={data ? data : "fade-down"} data-aos-duration={duration ? duration : "1500"}>
                        <div className="h-full flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blue-500">
                            <div className="relative">
                                <img
                                    alt="LENDEM Solutions Network"
                                    src="images/solutions.jpg"
                                    className="w-full h-96 align-middle rounded-t-lg"
                                />
                                <div className="absolute rounded-lg inset-0 bg-gray-400 mix-blend-multiply" aria-hidden="true" />
                            </div>
                            <blockquote className="relative p-8 mb-4">
                                <svg
                                    preserveAspectRatio="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 583 95"
                                    className="absolute left-0 w-full block"
                                    style={{
                                        height: "95px",
                                        top: "-94px"
                                    }}
                                >
                                    <polygon
                                        points="-30,95 583,95 583,65"
                                        className="text-blue-500 fill-current"
                                    ></polygon>
                                </svg>
                                <h4 className="text-xl font-bold text-white">
                                    Our Values & Commitments
                                </h4>
                                <p className="text-md font-light mt-2 text-white">
                                    {/* Core Values */}
                                    We specialize in the consumer finance industry. That's
                                    why we're committed to bringing you the best-in-class
                                    consumer marketing, fraud and risk management
                                    services and loan servicing for the customer profile
                                    that fits <span className="underline underline-offset-2">your company’s needs.</span>
                                </p>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;