import React, { Fragment } from "react";
import Navbar from "../components/Navbar.js";
import Locations from "../components/Locations.js";
import Footer from "../components/Footer.js";

function locations_page() {
  return (
    <Fragment>
        <Navbar bg_color="bg-gray-900" />
        <Locations margin_top="mt-20" data="none" duration="0" />
        <Footer />
    </Fragment>
  );
}

export default locations_page;