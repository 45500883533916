import React from "react";
import { BrowserRouter as Router, Routes , Route } from "react-router-dom";
import Home from "./js/pages/home_page.js";
import About from "./js/pages/about_page.js";
import Solutions from "./js/pages/solutions_page.js";
import Partners from "./js/pages/partners_page.js";
import Careers from "./js/pages/careers_page.js";
import Locations from "./js/pages/locations_page.js";
import Error from "./js/pages/error_page.js";
import Contact from "./js/pages/contact_page.js";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/locations" element={<Locations />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
}

export default App;
