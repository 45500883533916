import React, { Fragment } from "react";
import Solutions from "../components/Solutions.js";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";

function solutions_page() {
    return (
        <Fragment>
            <Navbar bg_color="bg-gray-900" />
            <Solutions margin_top="mt-20" data="none" duration="0" />
            <Footer />
        </Fragment>
    );
}

export default solutions_page;