import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

function Hero() {
    useEffect(() => {
        Aos.init({ delay: 200, once: false });
    }, []);

    return (
        <section className="relative w-full h-full pt-16 pb-32 flex content-center items-center justify-center"
            style={{
                minHeight: "100vh"
            }}>
            <div className="absolute top-0 w-full h-full bg-center bg-cover"
                style={{
                    backgroundImage: "url('images/hero.jpg')"
                }}>
                <span className="w-full h-full absolute opacity-75 bg-black"></span>
            </div>
            <div className="container mx-auto px-4" data-aos="fade-in" data-aos-duration="1500">
                <div className="items-center flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                        <div>
                            <h1 className="text-white font-semibold text-5xl">
                                Turning Data <span className="text-blue-500">Into Results</span>
                            </h1>
                            <p className="mt-4 text-lg text-gray-300">
                                LENDEM Solutions is a data-centered, decision management
                                technology firm that delivers unparalleled results to its clients by
                                transforming data into sound business decisions.
                            </p>
                            {/* <a href="/locations" className="bg-blue-500 text-white transition ease-in-out delay-120 duration-300 hover:bg-white hover:text-blue-500 border border-transparent hover:border-blue-500 py-3 px-6 font-semibold rounded-lg shadow-md inline-block mt-5">Locate Us</a> */}
                            <a href="/solutions" className="bg-golden-100 text-white transition ease-in-out delay-120 duration-300 hover:bg-white hover:text-golden-100 border border-transparent hover:border-golden-100 py-3 px-5 font-semibold rounded-lg shadow-md inline-block mt-5 ml-3">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
                style={{ height: "70px" }}
            >
                <svg
                    className="absolute bottom-0 overflow-hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                >
                    <polygon
                        className="text-white fill-current"
                        points="2560 0 2560 100 0 100"
                    ></polygon>
                </svg>
            </div>
        </section>
    );
}

export default Hero;