import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/free-regular-svg-icons";
import { faPeopleGroup, faMicrochip, faChartLine } from "@fortawesome/free-solid-svg-icons";
import Aos from "aos";
import "aos/dist/aos.css";

function Advantages({ margin_top, data, duration }) {
    useEffect(() => {
        Aos.init({ delay: 200, once: false });
    }, []);

    return (
        <section className={margin_top ? "pt-20 pb-48 bg-gray-100 font-poppins " + margin_top : "pt-20 pb-48 bg-gray-100 font-poppins"}>
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-center text-center mb-18" data-aos={data ? data : "fade-down"} data-aos-duration={duration ? duration : "1500"}>
                    <div className="w-full lg:w-6/12 px-4">
                        <h2 className="text-4xl font-bold">
                            Innovative Solutions
                        </h2>
                        <p className="text-lg text-gray-700 leading-relaxed m-4">
                            Whether you’re looking for a targeted skill to fill
                            an internal deficiency or a turn-key solution, we have the experience and
                            expertise to help you achieve your objectives.
                        </p>
                    </div>
                </div>
                <div className="flex flex-wrap mt-5 lg:mt-12 justify-center" data-aos={data ? data : "fade-up"} data-aos-duration={duration ? duration : "1500"}>
                    <div className="flex flex-wrap">
                        <div className="pt-6 w-full lg:w-4/12 px-4 text-center">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-md rounded-lg">
                                <div className="px-3 py-3 flex-auto">
                                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-500">
                                        <FontAwesomeIcon className="w-6 h-6 text-white" icon={faMicrochip} />
                                    </div>
                                    <h6 className="text-xl font-semibold text-black">Decisioning</h6>
                                    <p className="mt-2 mb-4 text-gray-600">
                                        Through data analytics and our proprietary scoring of hundreds of unique data points, we are able to help you immediately snuff out fraud as well as assess risk of non-payment leading to higher ROI.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="pt-6 w-full lg:w-4/12 px-4 text-center">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-md rounded-lg">
                                <div className="px-3 py-3 flex-auto">
                                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-500">
                                        <FontAwesomeIcon className="w-6 h-6 text-white" icon={faPeopleGroup} />
                                    </div>
                                    <h6 className="text-xl font-semibold text-black">Omni-Channel Customer Acquisitions</h6>
                                    <p className="mt-2 mb-4 text-gray-600">
                                        With our vast experience in Ping Tree Maximization, Digital Advertising and Direct Mail, we are able to make split-second decisions to help our client's maximize the lifetime value of their consumers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="pt-6 w-full lg:w-4/12 px-4 text-center">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-md rounded-lg">
                                <div className="px-3 py-3 flex-auto">
                                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-500">
                                        <FontAwesomeIcon className="w-6 h-6 text-white" icon={faChartLine} />
                                    </div>
                                    <h6 className="text-xl font-semibold text-black">Vintage Maximization</h6>
                                    <p className="mt-2 mb-4 text-gray-600">
                                        LENDEM has experience with several unique consumer products such as single payment installment, multi-payment installment, and lines of credit giving our clients the flexibility to provide products that match the consumer's need.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center items-center mt-10">
                <a href="/solutions" className="bg-blue-500 text-white transition ease-in-out delay-120 duration-300 hover:bg-white hover:text-blue-500 border border-transparent hover:border-blue-500 py-3 px-6 font-semibold rounded-lg shadow-md inline-block mt-5" data-aos="fade-in" data-aos-duration="1500">Learn More</a>
                </div>
            </div>
        </section>
    );
}

export default Advantages;