import React, { Fragment } from "react";
import About from "../components/About.js";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";

function about_page() {
    return (
        <Fragment>
            <Navbar bg_color="bg-gray-900" />
            <About margin_top="mt-20" data="none" duration="0" link="/solutions" />
            <Footer />
        </Fragment>
    );
}

export default about_page;